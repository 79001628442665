// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guessRed {
    border: 1px solid red;
    border-radius: 4px;
    min-height: 8px;
    min-width: 240px;
    color: white;

    margin: 4px;

    padding-left: 4px;
    padding-right: 4px;

    background-color: red;
}

.guessGreen {
    border: 1px solid green;
    border-radius: 4px;
    min-height: 8px;
    min-width: 240px;
    color: white;

    margin: 4px;

    padding-left: 4px;
    padding-right: 4px;

    background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;;IAEZ,WAAW;;IAEX,iBAAiB;IACjB,kBAAkB;;IAElB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;;IAEZ,WAAW;;IAEX,iBAAiB;IACjB,kBAAkB;;IAElB,uBAAuB;AAC3B","sourcesContent":[".guessRed {\n    border: 1px solid red;\n    border-radius: 4px;\n    min-height: 8px;\n    min-width: 240px;\n    color: white;\n\n    margin: 4px;\n\n    padding-left: 4px;\n    padding-right: 4px;\n\n    background-color: red;\n}\n\n.guessGreen {\n    border: 1px solid green;\n    border-radius: 4px;\n    min-height: 8px;\n    min-width: 240px;\n    color: white;\n\n    margin: 4px;\n\n    padding-left: 4px;\n    padding-right: 4px;\n\n    background-color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
